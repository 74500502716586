<template>
  <div v-show="this.authenticated">
    <div class="title">
      <h1>IPP - Innovare Project Planning</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><i class="fas fa-columns"></i></li>
          <li class="breadcrumb-item active" aria-current="page">
            Versão: {{ $store.getters.appVersion }}
          </li>
        </ol>
      </nav>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      authenticated: false,
    };
  },
  mounted() {
    this.authenticated = this.$store.getters.authenticated;
  },
  computed: {
    breadcrumbs: function () {
      console.dir(this.$store.getters.breadcrumbs);
      return this.$store.getters.breadcrumbs;
    },
  },
};
</script>
<style scoped>
.active {
  color: rgba(0, 0, 0, 0.685);
}
</style>
