<template>
  <nav id="sidebar" class="sidebar active" v-show="this.authenticated">
    <div :id="'b' + $store.state.user.id" class="sidebar-header">
      <img
        class="logoBig"
        src="../../assets/images/logo-innovare.png"
        alt="Logo"
      />
      <img
        class="logoSmall"
        src="../../assets/images/logo-innovare-small.png"
        alt="Logo"
      />
    </div>
    <ul class="list-unstyled components">
      <li>
        <router-link to="/" @click.native="changeMenu()"
          ><i class="fas fa-home"></i> <span>Home</span></router-link
        >
      </li>
      <li>
        <router-link to="/schedule" @click.native="changeMenu()"
          ><i class="fas fa-calendar-alt"></i> <span>Agenda</span></router-link
        >
      </li>
      <li>
        <router-link to="/backlog" @click.native="changeMenu()"
          ><i class="fas fa-poll"></i> <span>Backlog</span></router-link
        >
      </li>
      <li>
        <a
          id="dashboardSubmenuExpand"
          @click="changeMenu('dashboardSubmenu')"
          class="dropdown-toggle"
          v-if="$store.state.user.is_admin == 1"
        >
          <i class="fas fa-tasks"></i>
          <span>Gestão</span>
        </a>
        <ul class="collapse list-unstyled" id="dashboardSubmenu">
          <li>
            <router-link to="/projects-sprints" @click.native="changeMenu()"
              >Projetos/Sprints</router-link
            >
          </li>
          <li>
            <router-link to="/process" @click.native="changeMenu()"
              >Processos</router-link
            >
          </li>
          <li>
            <router-link to="/projects/planning" @click.native="changeMenu()"
              >Planejamento Projetos</router-link
            >
          </li>
          <li>
            <router-link to="/projectstatuses" @click.native="changeMenu()"
              >Status</router-link
            >
          </li>
          <li>
            <router-link to="/users" @click.native="changeMenu()"
              >Usuários</router-link
            >
          </li>
          <li>
            <router-link to="/tickets" @click.native="changeMenu()"
              >Tickets</router-link
            >
          </li>
          <li>
            <router-link to="/innover_applications" @click.native="changeMenu()"
              >Aplicações - Innovare</router-link
            >
          </li>
          <li>
            <router-link to="/holidays" @click.native="changeMenu()"
              >Feriados</router-link
            >
          </li>
        </ul>
      </li>
      <li>
        <a
          id="dashsupExpand"
          @click="changeMenu('dashsup')"
          class="dropdown-toggle"
        >
          <i class="fas fa-columns"></i>
          <span>Dashboards</span>
        </a>
        <ul class="collapse list-unstyled" id="dashsup">
          <li>
            <router-link to="/dashsup" @click.native="changeMenu('dashsup')"
              >Suporte</router-link
            >
          </li>
          <li>
            <router-link
              to="/consult-card"
              @click.native="changeMenu('dashsup')"
              >Gestão de Horas</router-link
            >
          </li>
        </ul>
      </li>
      <li>
        <a
          id="customerExpand"
          @click="changeMenu('customer')"
          class="dropdown-toggle"
          v-if="$store.state.user.is_admin == 1"
        >
          <i class="far fa-handshake"></i>
          <span>Clientes</span>
        </a>
        <ul class="collapse list-unstyled" id="customer">
          <li>
            <router-link
              to="/Customers"
              @click.native="changeMenu('changeMenu')"
              >Clientes</router-link
            >
          </li>
          <li>
            <router-link
              to="/usercustomers"
              @click.native="changeMenu('changeMenu')"
              >Usuarios</router-link
            >
          </li>
          <li>
            <router-link
              to="/typeofcontracts"
              @click.native="changeMenu('changeMenu')"
              >Tipos de Contrato</router-link
            >
          </li>
          <li>
            <router-link
              to="/contracts"
              @click.native="changeMenu('changeMenu')"
              >Contratos</router-link
            >
          </li>
          <li>
            <router-link
              to="/contracts/report"
              @click.native="changeMenu('changeMenu')"
              >Relatório de Contratos</router-link
            >
          </li>
          <li>
            <router-link
              to="/budgetings"
              @click.native="changeMenu('changeMenu')"
              >Orçamentos</router-link
            >
          </li>
        </ul>
      </li>

      <li>
        <a
          id="wfDeployExpand"
          @click="changeMenu('wfDeploy')"
          class="dropdown-toggle"
        >
          <i class="fas fa-cloud-upload-alt"></i>
          <span>Deploy</span>
        </a>
        <ul class="collapse list-unstyled" id="wfDeploy">
          <li>
            <router-link
              to="/wfdeploys"
              v-if="$store.getters.user.is_dev == 1"
              @click.native="changeMenu('wfDeploy')"
              >Developers</router-link
            >
          </li>
          <li>
            <router-link
              to="/wfdeploys/qa"
              v-if="$store.getters.user.is_QA == 1"
              @click.native="changeMenu('wfDeploy')"
              >QA - Quality Assurance</router-link
            >
          </li>
          <li>
            <router-link
              to="/wfdeploys/deploys"
              v-if="$store.getters.user.is_deployer == 1"
              @click.native="changeMenu('wfDeploy')"
              >Deploy</router-link
            >
          </li>
          <li>
            <router-link
              to="/wfdeploys/history"
              @click.native="changeMenu('wfDeploy')"
              >Histórico</router-link
            >
          </li>
        </ul>
      </li>

      <li>
        <a
          id="reportsExpand"
          @click="changeMenu('reports')"
          class="dropdown-toggle"
        >
          <i class="fas fa-file-excel"></i>
          <span>Relatórios</span>
        </a>
        <ul class="collapse list-unstyled" id="reports">
          <li>
            <router-link
              to="/tickets/report"
              @click.native="changeMenu('reports')"
              >Fechamento de Chamados (Legacy)</router-link
            >
          </li>
          <li>
            <router-link
              to="/report/report-sla"
              @click.native="changeMenu('reports')"
              >SLA de Chamados</router-link
            >
          </li>
          <li>
            <router-link
              to="/report/report-usercustomer"
              @click.native="changeMenu('reports')"
              >Chamados por Usuário</router-link
            >
          </li>
        </ul>
      </li>

      <li>
        <router-link to="/Wikis"
          ><i class="fas fa-book-reader"></i>
          <span>Base de Conhecimento</span></router-link
        >
        <!-- <a id="knowledgeExpand" @click="changeMenu('knowledge')" class="dropdown-toggle">
          <i class="fas fa-book-reader"></i>
          <span>Base de Conhecimento</span>
        </a> -->
        <!-- <ul class="collapse list-unstyled" id="knowledge">
          <li>

          </li>
        </ul> -->
      </li>

      <li>
        <router-link to="/WikiClients"
          ><i class="far fa-handshake"></i>
          <span>Procedimento de Clientes</span></router-link
        >
        <!-- <a id="know-clientExpand" @click="changeMenu('know-client')" class="dropdown-toggle">
          <i class="far fa-handshake"></i>
          <span>Procedimento de Clientes</span>
        </a> -->
        <!-- <ul class="collapse list-unstyled" id="know-client">
          <li>
          </li>
        </ul> -->
      </li>
      <li>
        <a
          id="my-boardExpand"
          @click="changeMenu('my-board')"
          class="dropdown-toggle"
        >
          <i class="fas fa-clipboard-list"></i>
          <span>Meu Quadro</span>
        </a>
        <ul class="collapse list-unstyled" id="my-board">
          <li>
            <router-link to="/tasks-day" @click.native="changeMenu('my-board')"
              >Tarefas do dia</router-link
            >
          </li>
          <li>
            <router-link to="/new-board" @click.native="changeMenu('my-board')">
              Meu Board
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/my-board" @click.native="changeMenu('my-board')">Meu Board</router-link>
          </li> -->
          <li>
            <router-link to="/team-board" @click.native="changeMenu('my-board')"
              >Team Board</router-link
            >
          </li>
        </ul>
      </li>
      <li>
        <router-link to="/daily"
          ><i class="fas fa-user-friends"></i> <span>Daily</span></router-link
        >
        <!-- <a
          class="cursor"
          aria-expanded="false"
          id="dailyExpand"
          @click="changeMenu('daily')"
        >
          <i class="fas fa-user-friends"></i>
          <span>Daily</span>
        </a> -->
        <!-- <ul class="collapse list-unstyled" id="daily">
          <li>
          </li>
        </ul> -->
      </li>
      <li>
        <a
          id="new_ticketsExpand"
          @click="changeMenu('new_tickets')"
          class="dropdown-toggle"
        >
          <i class="fas fa-envelope"></i>
          <span>Chamados</span>
        </a>
        <ul class="collapse list-unstyled" id="new_tickets">
          <li>
            <router-link
              to="/new_tickets"
              @click.native="changeMenu('new_tickets')"
              >Chamados Abertos</router-link
            >
          </li>
          <li>
            <router-link
              to="/closed_tickets"
              @click.native="changeMenu('new_tickets')"
              >Chamados Finalizados</router-link
            >
          </li>
          <li>
            <router-link
              to="/my_tickets"
              @click.native="changeMenu('my_tickets')"
              >Meus Chamados</router-link
            >
          </li>
          <li>
            <router-link
              to="/tickets-management"
              @click.native="changeMenu('tickets-management')"
              >Gestão de Chamados</router-link
            >
          </li>
          <li>
            <router-link
              to="/tickets-classifications"
              @click.native="changeMenu()"
              >Classificações</router-link
            >
          </li>
          <li>
            <router-link
              to="/ticketcategories"
              @click.native="changeMenu('new_tickets')"
              >Categorias</router-link
            >
          </li>
          <li>
            <router-link
              to="/tickets_status"
              @click.native="changeMenu('ticketstatus')"
              >Status</router-link
            >
          </li>
          <li>
            <router-link
              to="/ticket_notifications"
              @click.native="changeMenu('ticketstatus')"
              >Notificações de SLA</router-link
            >
          </li>
          <li>
            <router-link
              to="/tickets_panel"
              @click.native="changeMenu('ticketstatus')"
              >Painel de SLA</router-link
            >
          </li>
          <li>
            <router-link
              to="/process_names"
              @click.native="changeMenu('process_names')"
              >Nome dos Processos</router-link
            >
          </li>
          <li>
            <router-link
              to="/process_areas"
              @click.native="changeMenu('process_areas')"
              >Área dos Processos</router-link
            >
          </li>
        </ul>
      </li>
      <li>
        <router-link to="/video"
          ><i class="fa fa-video-camera"></i>
          <span>Vídeo Call</span></router-link
        >
      </li>
    </ul>
  </nav>
</template>


<script>
export default {
  data() {
    return {
      authenticated: false,
      user: {},
    };
  },
  mounted() {
    this.authenticated = this.$store.getters.authenticated;
    this.user = this.$store.getters.user;

    const self = this;
    self.updateId();

    $(function () {});
  },
  methods: {
    updateId() {
      const self = this;
      self.id = self.$store.state.user.id;
    },
   changeMenu(page) {
      if (page != undefined && $("#" + page).hasClass("show") == false) {
        $("a").attr("aria-expanded", "false");
        $("ul").removeClass("show");
        $("#" + page + "Expand").attr("aria-expanded", "true");
        document.getElementById(page).classList.add("show");
      } else {
        $("a").attr("aria-expanded", "false");
        $("ul").removeClass("show");
      }
    },
  },
};
</script>
<style>
</style>
