<template>
  <div>
    <!-- Header -->
    <header id="header" class="header">
      <div class="header-left">
        <button type="button" id="sidebarCollapse" class="btn btn-sidebar">
          <i class="fas fa-bars"></i>
        </button>
        <!-- <form method="get" action>
          <div class="form-group search">
            <input
              type="text"
              class="form-control form-control-sm"
              id="searchForm"
              placeholder="O que está procurando?"
            />
          </div>
        </form>-->
      </div>
      <div class="header-right">
        <div class="dropdown notification">
          <a
            class="dropdown-toggle"
            href="#"
            id="notifications"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img class="photo" :src="this.$store.getters.user.picture" alt="profile-picture" />
            <span style="padding-right: 10px;">{{$store.getters.user.name}}</span>
            <i class="fas fa-caret-down"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="notifications">
            <router-link style="text-decoration: none;" to="/profile">
              <a class="dropdown-item" href>
                <i class="fas fa-user"></i>
                <span class="message">Meu Perfil</span>
              </a>
            </router-link>
            <div class="dropdown-divider"></div>
            <router-link style="text-decoration: none;" to="/user/change-password">
              <a class="dropdown-item" href>
                <i class="fas fa-key"></i>
                <span class="message">Alterar Senha</span>
              </a>
            </router-link>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" v-on:click="logout" href="#">
              <i class="fas fa-sign-out-alt"></i>
              <span class="message">Sair</span>
            </a>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      authenticated: false,
    };
  },
  methods: {
    updateId() {
      const self = this;
      self.id = self.$store.state.user.id;
    },
    options() {
      $("#options").collapse("toggle");
    },
    logout: function () {
      let self = this;
      let api = this.$store.state.api + "logout";

      self.$http
        .post(api)
        .then((response) => {
          localStorage.removeItem("token");

          self.$router.push({ path: "/login" });
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.$message("Erro", "Erro ao realizar logout", "error");
        });
    },
  },
  mounted() {
    const self = this;

    this.authenticated = this.$store.getters.authenticated;

    let id = self.$store.getters.user.id;

    function SmoothlyMenu() {
      if (
        !$("body").hasClass("mini-navbar") ||
        $("body").hasClass("body-small")
      ) {
        // Hide menu in order to smoothly turn on when maximize menu
        $("#side-menu").hide();
        // For smoothly turn on menu
        setTimeout(function () {
          $("#side-menu").fadeIn(400);
        }, 200);
      } else if ($("body").hasClass("fixed-sidebar")) {
        $("#side-menu").hide();
        setTimeout(function () {
          $("#side-menu").fadeIn(400);
        }, 100);
      } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        $("#side-menu").removeAttr("style");
      }
    }

    // Minimalize menu
    $(".navbar-minimalize").on("click", function () {
      $("body").toggleClass("mini-navbar");
      SmoothlyMenu();
    });

    $("#sidebarCollapse").on("click", function (event) {
      $("#sidebar").toggleClass("active");
    });
  },
};
</script>


<style scoped>
.photo {
  position: inline-relative;
  width: 35px;
  height: 35px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 1px;
  margin-top: 0%;
  border: 1px solid rgba(17, 17, 17, 0.568);
}
.header-right .notification .dropdown-menu {
  padding: 5px 0;
  min-width: 0px !important;
  margin-top: 17px;
}
.header-right .notification {
  float: right;
  margin-left: 15px;
  margin-top: 0px;
}
</style>
