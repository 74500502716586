<template>
  <div id="app" class="">
    <div class="wrapper">
        <left-navbar></left-navbar>

        <div id="content" class="content">

            <header-navbar></header-navbar>
            <breadcrumb></breadcrumb>

                <div class="main-content">
                    <router-view></router-view>
                </div>



        </div>
    </div>
  </div>
</template>

<script>
    import HeaderNavbar from './HeaderNavbar'
    import LeftNavbar from './LeftNavbar'
    import Breadcrumb from './Breadcrumb'
    export default {
        name: "app",
        data: () => ({
            drawer: null,
            authenticated: false
        }),
        props: {
            source: String
        },
        mounted () {
            this.authenticated = this.$store.getters.authenticated

            $('body').removeClass('top-navigation')
            $('body').removeClass('custom-login')
        },
        components: {
            HeaderNavbar, LeftNavbar, Breadcrumb
        }
    }
</script>

<style>
    body {
        padding: 0px !important;
    }
    #wrapper{
        margin-top: 82px;

    }
</style>

